<template>
  <PageTitle :title="$t('CONVERSATION.TITLE')" class="page-title"/>
  <div class="page-section-text section-text">
    {{ $t('CONVERSATION.DESCRIPTION') }}
  </div>

  <div class="conversations-container">
    <div v-for="minigame in fakeMinigameData"
         :key="minigame.id">
      <div class="minigame-container">
        <img :src="minigame.image" class="minigame-icon"/>
        <div class="minigame-content">
          <div class="text-title"> {{ minigame.title }}</div>
          <div class="text-description"> {{ minigame.description }}</div>
          <div class="button-start"> {{ $t("CONVERSATION.START") }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PageTitle from '@/components/elements/texts/PageTitle'


export default {
  name: 'Conversation',
  components: {
    PageTitle
  },
  setup(props) {
    const fakeMinigameData =
      [
        {
          id: 0,
          duration: 15,
          title: 'CYBERCRIME',
          description: 'Wanneer heb je met cybercriminaliteit te maken en wanneer niet?',
          image: require('./../../assets/img/img_mediamwisdom1@2x.png'),
        },
        {
          id: 0,
          duration: 15,
          title: 'CYBERCRIME',
          description: 'Wanneer heb je met cybercriminaliteit te maken en wanneer niet?',
          image: require('./../../assets/img/img_mediamwisdom1@2x.png'),
        },
        {
          id: 0,
          duration: 15,
          title: 'CYBERCRIME',
          description: 'Wanneer heb je met cybercriminaliteit te maken en wanneer niet?',
          image: require('./../../assets/img/img_mediamwisdom1@2x.png'),
        }
      ]

    return {
      fakeMinigameData
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/assets/css/base.variables";
@import "~@/assets/css/base.mixins";



.conversations-container{
 display: flex;
 flex-wrap: wrap;
 gap: rem(30);
}

.minigame-container{
  width: rem(260);
  height: rem(550);
  display: flex;
  flex-direction: column;
  gap: rem(20);
  background-color: #626DC6;
  border-radius: rem(20);
}

.minigame-icon{
  width: rem(260);
  height: rem(260);
}

.minigame-content{
  display: flex;
  margin: rem(20);
  flex-direction: column;
  color: white;
  gap: rem(20);
  height: rem(290);
  justify-content: space-around;
}

.text-title{

}

.text-description{

}

.button-start{
  align-self: center;
  width: 100%;
  text-align: center;
  background-color: white;
  color: #757887;
  border-radius: rem(10);
  font-weight: 800;
  font-size: rem(20);
  padding: rem(15) 0;
  box-shadow: rem(4) rem(4) 0 0 rgba(0,0,0,0.1);
}
</style>